.app {
  font-family: Arial, sans-serif;
  padding: 20px;
  text-align: center;
}

.dark-mode {
  background-color: #222;
  color: #fff;
}

.toggle-dark-mode {
  position: fixed;
  top: 10px;
  right: 10px;
}

.form-group {
  margin-bottom: 10px;
}

.form-group input {
  width: 600px;
  padding: 10px;
  font-size: 16px;
}

.submit-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
}

.submit-btn:hover {
  background-color: #0069d9;
}

footer {
  margin-top: 20px;
}

.response-area {
  max-width: 800px;
  word-wrap: break-word;
  margin: 1rem auto;
}

.explore-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}


.explore-buttons button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 15px;
  min-width: 150px;
  margin: 5px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
}

.explore-buttons button:hover {
  background-color: #0069d9;
}


.explore-prompts button:hover {
  background-color: #0069d9;
}
